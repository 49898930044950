import { Checkbox, Form, Input, Modal, Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { EditAddressStore } from './store';

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 20 },
};
export const EditAddressModal: React.FC<{ store: EditAddressStore; }> = observer((props) => {
  const {
    addressOptions,
    loadData,
    setRef,
    visible,
    loading,
    onClose,
    onOk,
    confirmLoading,
  } = props.store;
  return (
    <Modal
      className={styles.editModal}
      confirmLoading={confirmLoading}
      maskClosable={false}
      onCancel={onClose}
      onOk={onOk}
      open={visible}
      title="编辑收货人信息"
      width={524}
    >
      <Spin spinning={loading}>
        <Form
          ref={setRef}
          {...formLayout}
        >
          <Form.Item
            label=" 收货人"
            name="receiverName"
            required
            rules={[{ required: true }]}
          >
            <Input style={{ width: 300 }}/>
          </Form.Item>
          <Form.Item
            label=" 手机号"
            name="receiverMobile"
            required
            rules={[
              { required: true },
              {
                pattern: /^1\d{10}/,
                message: '手机号格式不正确',
              },
            ]}
          >
            <Input style={{ width: 300 }}/>
          </Form.Item>
          <Form.Item
            label="所在地区"
            name="address"
            required
            rules={[{ required: true }]}
          >
            {/* <Cascader
              loadData={loadData}
              options={addressOptions}
            /> */}
            <Input
              bordered={false}
              disabled
              style={{ width: 300 }}
            />
          </Form.Item>
          <Form.Item
            label="详细地址"
            name="receiverAddress"
            required
            rules={[{ required: true }]}
          >
            <Input style={{ width: 300 }}/>
          </Form.Item>
          <Form.Item
            label=""
            name="defaultAddress"
            valuePropName="checked"
          >
            <Checkbox style={{ width: 300 }}>
              设为默认地址，方便下次使用
            </Checkbox>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
});
