import { Button, Cascader, Checkbox, Form, Input, Spin, Tabs } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import downArrow from '../../assets/images/downArrow.png';
import { PUBLIC_IMAGE_URL } from '../../utils/common';
import styles from './index.less';
import type { Address, Option } from './interface';
import type OrderCheckStore from './store';
import { toJS } from 'mobx';

interface Props {
  orderCheckStore: OrderCheckStore;
}

const formLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 10 },
};

export const AddressManager = observer((props: Props) => {
  const { orderCheckStore } = props;
  const {
    addressList,
    allAddressList,
    showMoreAddress,
    mallReceiverAddressId,
    setAddress,
    setDefaultAddress,
    delAddress,
    onToggleAddress,
    openEditModal,
    address,
    onAddressChange,
    parsingAddress,
    setNewAddRef,
    loadData,
    addressOptions,
    parsingLoading,
    changeAddressTab,
    addressTabKey,
    onNewAddressChange,
  } = orderCheckStore;

  return (
    <div className={styles.addressInfo}>
      <Tabs
        activeKey={addressTabKey}
        items={[
          {
            key: '1',
            label: '常用地址',
            children: (
              <CommonAddress
                addressList={addressList}
                allAddressList={allAddressList}
                changeAddressTab={changeAddressTab}
                delAddress={delAddress}
                mallReceiverAddressId={mallReceiverAddressId}
                onToggleAddress={onToggleAddress}
                openEditModal={openEditModal}
                setAddress={setAddress}
                setDefaultAddress={setDefaultAddress}
                showMoreAddress={showMoreAddress}
              />),
          },
          {
            key: '2',
            label: '新建收货人信息',
            children: (
              <Spin spinning={parsingLoading}>
                <NewAddress
                  address={address}
                  addressOptions={addressOptions}
                  loadData={loadData}
                  onAddressChange={onAddressChange}
                  onNewAddressChange={onNewAddressChange}
                  parsingAddress={parsingAddress}
                  setNewAddRef={setNewAddRef}
                />
              </Spin>),
          },
        ]}
        onChange={changeAddressTab}
        tabBarGutter={28}
      />
    </div>
  );
});

interface INewAddress {
  address: string;
  addressOptions: Option[];
  onAddressChange: (e) => void;
  parsingAddress: () => void;
  setNewAddRef: (ref) => void;
  loadData: (params) => void;
  onNewAddressChange: (values: Record<string, any>) => void;
}

const NewAddress: React.FC<INewAddress> = (props) => {
  const {
    address,
    onAddressChange,
    parsingAddress,
    setNewAddRef,
    loadData,
    addressOptions,
    onNewAddressChange,
  } = props;
  return (
    <div
      className={styles.newAddress}
      id="address"
    >
      <div className={styles.manualCreate}>
        <header className={styles.manualHeader}>
          手动创建
        </header>
        <Form
          onValuesChange={onNewAddressChange}
          ref={setNewAddRef}
          {...formLayout}
        >
          <Form.Item
            label="收货人"
            name="name"
            required
            rules={[{ required: true }]}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="手机号"
            name="phone"
            required
            rules={[
              { required: true },
              {
                pattern: /^1\d{10}/,
                message: '手机号格式不正确',
              },
            ]}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label="所在地区"
            name="address"
            required
            rules={[{ required: true }]}
            wrapperCol={{ span: 14 }}
          >
            <Cascader
              loadData={loadData}
              options={toJS(addressOptions)}
            />
          </Form.Item>
          <Form.Item
            label="详细地址"
            name="detail"
            required
            rules={[{ required: true }]}
            wrapperCol={{ span: 14 }}
          >
            <Input/>
          </Form.Item>
          <Form.Item
            label=""
            name="setDefault"
            valuePropName="checked"
            wrapperCol={{ span: 14 }}
          >
            <Checkbox>
              设为常用地址，方便下次使用
            </Checkbox>
          </Form.Item>
        </Form>
      </div>
      <div className={styles.smartParsing}>
        <header className={styles.parsingHeader}>
          智能解析收货地址
        </header>
        <div>
          <Input.TextArea
            onChange={onAddressChange}
            onPressEnter={parsingAddress}
            placeholder="粘贴或输入文本可快速识别地址信息，如：张三13800138000 江苏省苏州常熟市莫干路1号云裳大厦A栋21楼衫数科技"
            style={{
              height: '89px',
              resize: 'none',
            }}
            value={address}
          />
        </div>
        <div className={styles.parsingBtn}>
          <Button
            onClick={parsingAddress}
            type="primary"
          >
            一键解析
          </Button>
        </div>
      </div>
    </div>
  );
};

// 常用地址
interface ICommonAddress {
  addressList: Address[]; // 展示地址
  mallReceiverAddressId: number; // 选中的地址
  setDefaultAddress: (id: number) => void; // 设置默认地址
  delAddress: (id: number) => void; // 删除地址
  setAddress: (address: Address) => void;
  allAddressList: Address[]; // 所有地址
  openEditModal: (id) => void;
  onToggleAddress: () => void;
  showMoreAddress: boolean;
  changeAddressTab: (activeKey: string) => void;
}

const CommonAddress: React.FC<ICommonAddress> = observer((props) => {
  const {
    addressList,
    mallReceiverAddressId,
    setDefaultAddress,
    delAddress,
    allAddressList,
    onToggleAddress,
    showMoreAddress,
    setAddress,
    openEditModal,
    changeAddressTab,
  } = props;
  return (
    <div className={styles.commonWrapper}>
      {allAddressList?.length > 0 ? (
        <>
          {addressList?.map((item) => {
            return (
              <div
                className={styles.address}
                key={item.id}
              >
                <div
                  className={styles.leftPart}
                >
                  <div
                    className={mallReceiverAddressId === item.id ? `${styles.receiverName} ${styles.borderSelect}` : `${styles.receiverName}`}
                    onClick={() => setAddress(item)}
                  >
                    <span>
                      {item.receiverName}
                    </span>
                    {mallReceiverAddressId === item.id && (
                      <img
                        className={styles.selectedCorner}
                        src={`${PUBLIC_IMAGE_URL}selectIcon.png`}
                      />
                    )}
                  </div>
                  <span className={styles.mr12}>
                    {item.receiverMobile}
                  </span>
                  <span>
                    {item.province}
                    {item.city}
                    {item.district}
                    {item.receiverAddress}
                  </span>
                  {item.defaultAddress === 1 && (
                    <div className={styles.defaultIcon}>
                      默认地址
                    </div>
                  )}
                </div>
                <div>
                  {!item.defaultAddress && (
                    <Button
                      onClick={() => setDefaultAddress(item.id)}
                      type="link"
                    >
                      设为默认地址
                    </Button>
                  )}
                  <Button
                    onClick={() => openEditModal(item.id)}
                    type="link"
                  >
                    编辑
                  </Button>
                  {mallReceiverAddressId !== item.id && (
                    <Button
                      onClick={() => delAddress(item.id)}
                      type="link"
                    >
                      删除
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
          <div
            className={styles.moreAddress}
            onClick={onToggleAddress}
          >
            <span>
              {showMoreAddress ? '收起地址' : '更多地址'}
            </span>
            <img
              className={showMoreAddress ? `${styles.downArrow} ${styles.rotate180}` : `${styles.downArrow}`}
              src={downArrow}
            />
          </div>
        </>
      ) : (
        <div className={styles.noAddress}>
          <span>
            暂无常用收货地址，
          </span>
          <span
            className={styles.jumpToNewAdd}
            onClick={() => changeAddressTab('2')}
          >
            立即新建
          </span>
        </div>
      )}

    </div>
  );
});
